.projectContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 0 4%;
}

.project {
    background-color: #f7f7f7;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.project a {
    text-decoration: none;
    transition: transform 0.3s ease-in-out;
}

.project a:hover {
    transform: scale(1.05);
}

.project img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 15px;
}

.project h3 {
    margin: 10px 0 5px;
    font-size: 20px;
}

.project p {
    text-align: center;
    font-size: 14px;
}

.social-icons {
    text-align: center;
    }

.social-icons i {
    margin: 0 5px;
    color: #37436D;
}

@media screen and (max-width: 768px) {


    .projectContainer {
        grid-template-columns: 1fr;
    }


}