.hero {
    background-color: #37436D;
    text-align: center;
    background-image: linear-gradient(rgba(55, 67, 109, 0.7), rgba(55, 67, 109, 0.7)), url('images/Chloe\ Banner.png');
    background-size: cover;
    background-position: center;
    height: 600px;
    position: relative;
}


.hero-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.accent {
    color: #9F00FF;
}

.hero a {
    width: 200px;
    height: 60px;
    line-height: 60px;
    border-radius: 3px;
    text-transform: capitalize;
    color: #fff;
    background: #b636ff;
    border: 1px solid #b636ff;
    box-shadow: 0 5px 20px rgba(0, 0, 0, .2);
    transition: 0.3s linear;
    padding: 15px;
    text-decoration: none;
}

.hero h1 {
    color: #fff;
    font-size: 54px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.5;
}

.hero h2 {
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    text-transform: uppercase;
    margin: 30px 0 60px;
    letter-spacing: 1px;
}